import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import { Row, Col, Jumbotron, Card } from 'react-bootstrap';

import 'fontsource-roboto';

// Logo

import Logo from '../../static/assets/images/logo_BD.svg';

const NavBarBarn = styled('div')({
    width: '100%',
});

const NavButtonBarn = withStyles((theme) => ({
    textPrimary: {
        fontSize: '20px',
        '&:hover': {
            borderBottom: 'solid 1px white'
        },
    },
}))(Button);

export default function FooterDefault(props) {
    return <>
        {/* <Row>
            <Col className="text-center py-3" xs={{ span: 6, offset: 3 }}>
                <p style={{ opacity: '0.5', fontSize: '16px', fontFamily: 'sans-serif !important' }}>© 2020 Barn Door Media</p>
            </Col>
        </Row> */}
    </>
}