import React, { useState, useEffect} from 'react';
import { Link } from "gatsby"
// Material UI
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Row, Col, Container, Badge } from 'react-bootstrap';

import 'fontsource-roboto';

import styled, {keyframes} from "styled-components";

import green from '@material-ui/core/colors/green';
import purple from '@material-ui/core/colors/purple';

import NavBar from '../components/NavBar';
import CallToAction from '../components/CallToAction';
import FooterDefault from '../components/FooterDefault';

import Logo from '../../static/assets/images/logo_LCP.png';
import Splash from '../../static/assets/images/LCP_splash.jpg';
import Spinner from "../../static/assets/images/spinner.gif";

import { FcEngineering } from "react-icons/fc";
import { TiMinusOutline } from 'react-icons/ti';

import SEO from "../components/seo"

// import ScrollTrigger from 'react-scroll-trigger';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: green[800],
        },
        secondary: {
            main: purple[500],
        },
    },
});

const FadeIn = keyframes`
0%{
    opacity: 0;
    top: -13px;

 }
100%{
    opacity: 1;
    top: 0px;
}
`

const LithRow = styled(Row)`
background-size: cover;
background-repeat: none;
width: 100%;
`

const WebsiteSpan = styled.span`
animation: ${FadeIn} 3s forwards;
position: relative;

& > h5{
 margin-left: 40px;
}
`;

const LoadedImg = styled.img`
animation: ${FadeIn} 3s forwards;
`;

const BadgeLithgow = styled(Badge)`
animation: ${FadeIn} 3s forwards;
animation-delay: 1s;
opacity: 0;
`

export default function Home() {
    

    // const [scrolledPast, setScrolledPast] = React.useState(false);
    // const [scrollStarted, setScrollStarted] = React.useState(false);

    // const onEnterViewport = () => {
    //     console.log("scroll start")
    //     setScrolledPast(false);
    //     setScrollStarted(true);
    // }

    // const onExitViewport = () => {
    //     console.log("scroll end")
    //     setScrolledPast(true);
    //     setScrollStarted(true);
    // }

    // const onScrolling = () => {
    //     console.log("scrolling");
    //     setScrolledPast(false);
    // }

    return <ThemeProvider theme={theme}>
        <SEO title="Home" />
        <div style={{
            display: "flex",
            minHeight: "calc(100vh)",
            flexDirection: "column"
        }}>
            <Container fluid style={{ flex: "1", display: "flex", flexDirection: "column", minHeight: "100%", overflowY: "hidden"}}>
                {/* <NavBar /> */}
                <Row style={{ flex: "1"}}>
        <Row>
            <Col>
                        <Badge style={{ margin: "10px", width: "calc(100% - 20px)", top: "16px" }} className=" text-right">
                            <WebsiteSpan className="h-100 d-flex flex-column justify-content-center">
                                    <FcEngineering size={30} style={{
                                        position: "absolute",
                                        left: "-20px",
                                        top: "1px",
                                        marginLeft: "24px"}}/>
                                <h5 style={{ color: "orange", textTransform: 'uppercase', lineHeight: "30px",}}>Website Under Construction.</h5>
                                </WebsiteSpan>
                        </Badge>
            </Col>
        </Row>
                    <LithRow noGutters>
                <Col lg={1} md={1} sm={1}>
 
                        <BadgeLithgow className="h-100 d-flex flex-column justify-content-start position-absolute" style={{top: "50px"}}>
                            <img width="80%"  className="m-5" src={Logo} alt="Lithgow Community Power" />
                            </BadgeLithgow>
                        {/* Splash Background Image */}
                </Col>
                        <Col sm={{span:4, offset: 1}} className="justify-content-center">

                            <LoadedImg
                                style={{position: "relative", top: "55px", right: "50px" }}
                                src={Splash}
                                width="98%"
                                className="my-5 mx-auto img-fluid"
                            />

                </Col>
                <Col lg={{span: 5, offset: 0}} md={{span: 10, offset: 1}} className="h-100 d-flex flex-column justify-content-start text-left" style={{background: "white"}}>
                    <div className="p-5">
                    <h4 style={{ marginBottom: "30px", color: "black", borderBottom: "lightgrey solid 1px", paddingBottom: "42px"}}>ABOUT THE LITHGOW<br />COMMUNITY POWER PROJECT.</h4>
                    <p style={{marginBottom: "0"}}>We recognise the power of an organised community to shape its future and prosperity, 
                    for instance through joint action to reduce transport and energy bills, or through collective purchasing of new technology, 
                    or community support for diversification of the local economy.            
                         </p>
                    <TiMinusOutline size={28} className="mx-auto my-2" />
                                <p style={{ marginBottom: "0" }}>The Project’s goal is to enhance the living standards and financial well-being of households in the Lithgow region by changing how we use transport and energy technologies to preserve the resources of the local area.       
                         </p>
                    <TiMinusOutline size={28} className="mx-auto my-2" />
                    <p>We will support and implement programs that foster economic growth and diversification, through community power and collaboration with government, 
                        industry and science.</p>
                   </div>
                   <CallToAction style={{ width: '100%' }} tagLine="CONTACT US" />
                </Col>
            </LithRow>
                </Row>
                </Container>
        </div>
    </ThemeProvider>
}
