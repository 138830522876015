import React, { useState, useEffect, createRef } from "react";
import styled, {keyframes} from 'styled-components';
import lottie from "lottie-web";
import animation from "../../src/static/assets/lotties/lf30_editor_62o4m3xo.json";
// Logo

import Logo from '../../static/assets/images/logo_BD.svg';

// const w = window.innerWidth;
// const h = window.innerHeight;

const VideoDiv = styled.video`
    width: 100%;
    background: rgba(0,0,0,0.9);
    height: 500px;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s;
    `;

export default function Video ({ ...props }) {
    console.log(props);

    const videoFile = React.useRef(null);

    const [videoSource, setVideoSource] = useState(null);
    const [canPlayVideo, setCanPlayVideo] = useState(false);

    useEffect(() => {
      setVideoSource(props.videoSrcURL);
      if (videoSource) {
        props.clickBG
          ? videoFile.current.play()
          : videoFile.current.pause();
      }
      console.log("Video File Ref___" + videoFile);
    }, [props.clickBG]);

    useEffect(() => {
        console.log('video mounting');
        setVideoSource(props.videoSrcURL);
        console.log("video source" + videoSource);
        return () => {
            console.log('video unmounting');
            setVideoSource(null);
        }
    }, [])

    const loadedFunction = () => {
        console.log('video ready');
        return setCanPlayVideo(true);
    }

    if (videoSource === null) return null
    return <div className="video">
      <VideoDiv
                ref={videoFile}
                onPlay={props.onPlay}
                onPause={props.onPause}
                width="100%"
                height="auto"
                autoPlay
                loop={true}
                onClick={props.onPause}    
                onCanPlay={loadedFunction}
                controls={props.controller}   
                muted
            >
                <source src={videoSource} type="video/mp4"></source>
      </VideoDiv>
        </div>
}