import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import { Row} from 'react-bootstrap';

export const NavBarLithgow = styled('div')({
    width: '100%',
});

export const NavBarLithgowRow = styled(Row)`
    width: 33%;
    margin: 0 auto;

    @media screen and (max-width: 976px){
    width: 100%;
    margin: auto;
    }
 `;

export const NavButtonLithgow = withStyles((theme) => ({
    textPrimary: {
        fontSize: '20px',
        borderBottom: 'solid 1px transparent',
        borderRadius: '6px 6px 0 0',
        transition: '0.66s',
        color: 'black',
        '&:hover': {
            borderBottom: 'solid 1px white',
            background: 'none'
        },
    },
}))(Button);

export const BackgroundSlider = styled.div`
    position: absolute;
    height:500px;
    width:100%;
    z-index:-1;
    background:url(${props => props.LithgowImage});
    background-size: cover;
    background-position: center;
    `;

export const LithgowTextLogo = styled.h4`
text-transform: uppercase;
color: grey;
font-size: 90%;
letter-spacing: 0.15em;
border-bottom: grey 1px solid;
line-height: 15px;
height: 30%;
margin-left: 25px;
`;