import React from 'react';
import { Link } from "gatsby";
// Material UI
import styled, { keyframes } from 'styled-components';

import Button from '@material-ui/core/Button';

import { Row, Col, Jumbotron } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import 'fontsource-roboto';

// Logo

import Logo from '../../static/assets/images/logo_BD.svg';

const FooterFade = keyframes`
  0% {
    opacity: 0;
    bottom: -100px;
  }

  50%{
    opacity: 0;
    bottom: -66px; 
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
`;

const FooterRow = styled(Row)`
 animation: ${FooterFade} 2s ease forwards;
 margin: 0 auto;
 height: 180px;
 border-radius: 0 30px 0 0;
`;

const ActionButton = styled(Button)`
    background: transparent !important;
    padding: 24px 48px !important;
    box-shadow: none !important;
    border: 1px solid #2e7d32 !important;
    text-transform: uppercase;

    &:hover{
        box-shadow:  0px 3px 3px rgba(0,0,0,0.1) !important;
        background: white !important;
    }
    `

const ActionLink = styled.a`
&:hover{
    all:unset
}
&:active{
    all:unset
}
&  > h5{
    font-family: sans-serif;
    color: #2e7d32;
    font-weight: 300;
    font-size: 1em;
}
`

const ActionLine = styled.h3`
color: black; 
margin-bottom: 18px; 
font-weight: 600;
letter-spacing: 0.02em;
position:relative;
font-size: 1.11em;
`

export default function CallToAction(props) {

    React.useEffect(() => {
        console.log(props.scrolledPast + props.scrollStarted);
    }, [props.scrolledPast, props.scrollStarted]);

    return <>
        <FooterRow style={{ marginBottom: '0'}}>
            <Jumbotron className="w-100 text-center" style={{ background: 'white', borderRadius: '0 0', marginBottom: '0', padding: " 2.4em 15px" }}>
                <ActionLine> {props.tagLine} <span style={{ color: "#cc0000", fontSize: "110%", position:'relative', left: "-5px" }}>{props.punctuation}</span> </ActionLine>
                <ActionButton secondary variant='contained' className='mx-5'>
                    <ActionLink href="mailto:info@LCPP.com.au"><h5 style={{ lineHeight: '0', padding: '0', margin: '0' }}>info@LCPP.com.au</h5></ActionLink>
                </ActionButton>
            </Jumbotron>
        </FooterRow>
    </>

}
